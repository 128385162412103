import axios, { AxiosResponse, AxiosError } from "axios";

type IRequestProps = {
  uri: string;
  success: (response: AxiosResponse) => void;
  error: (error: AxiosError) => void;
  config?: { [key: string]: { [key: string]: string } };
};

const apiUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL: apiUrl,
});

export function getRequest(RequestProps: IRequestProps) {
  const { uri, success, error, config } = RequestProps;
  instance
    .get(uri, config ?? {})
    .then(success)
    .catch(error);
}

export function postRequest(RequestProps: IRequestProps) {
  const { uri, success, error, config } = RequestProps;
  instance
    .post(uri, config ?? {})
    .then(success)
    .catch(error);
}

export function authGetRequest(RequestProps: IRequestProps) {
  const auth = sessionStorage.getItem("auth");
  if (auth) {
    RequestProps.config = {
      headers: { Authorization: `Bearer ${auth}` },
    };
    getRequest(RequestProps);
  }
}

export function authPostRequest(RequestProps: IRequestProps) {
  const auth = sessionStorage.getItem("auth");
  if (auth) {
    RequestProps.config = {
      headers: { Authorization: `Bearer ${auth}` },
    };
    postRequest(RequestProps);
  }
}
