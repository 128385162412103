import { Row, Col } from "reactstrap";

import logo from "../../../assets/logo.png";

export default function LogoRow() {
  return (
    <Row>
      <Col
        className="text-center"
        md={{
          offset: 3,
          size: 6,
        }}
        sm="12"
      >
        <img src={logo} alt="" />
      </Col>
    </Row>
  );
}
