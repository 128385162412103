import { useState, useEffect, Fragment } from "react";
import LogoRow from "../Shared/LogoRow/LogoRow";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { authGetRequest } from "../../lib/axios";

type CityType = {
  cities_id: string;
  country: string;
  name: string;
  count: number;
};

type LocationType = {
  locations_id: string;
  name: string;
};

type QueryType = {
  query: string;
};

export default function Home() {
  const [open, setOpen] = useState("");
  const toggle = (id: string) => {
    if (open === id) {
      setOpen("");
      setLocations(iniitalLocations);
    } else {
      setOffset(initialOffset);
      setLimit(iniitalLimit);
      setQuery(initialQuery);
      setOpen(id);
      const tmpCity =
        cities.find((city) => city.cities_id === id) ?? initialCity;
      setCurrentCity(tmpCity);
      getLocations(id, initialQuery, initialOffset, iniitalLimit);
    }
  };
  const initialQuery = "-";
  const initialOffset = 1;
  const iniitalLimit = 50;
  const initialCity: CityType = {
    cities_id: "",
    country: "",
    name: "",
    count: 0,
  };
  const [resultState, setResultState] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [currentCity, setCurrentCity] = useState(initialCity);
  const [offset, setOffset] = useState(initialOffset);
  const [limit, setLimit] = useState(50);
  const [query, setQuery] = useState(initialQuery);
  const iniitalCities: CityType[] = [];
  const [cities, setCities] = useState(iniitalCities);
  const iniitalLocations: LocationType[] = [];
  const [locations, setLocations] = useState(iniitalLocations);
  const [locationsCount, setLocationsCount] = useState(0);
  const { t } = useTranslation();
  const schema = yup
    .object({
      query: yup.string().required(),
    })
    .required();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      query: "",
    },
  });

  const getLocations = (
    id: string,
    query: string,
    offset: number,
    limit: number
  ) => {
    authGetRequest({
      uri: `/cities/${id}/locations/${query}/${offset}/${limit}`,
      success: function (response) {
        if (response.data.payload.locations_count) {
          setLocationsCount(response.data.payload.locations_count);
        }
        if (response.data.payload.locations) {
          setLocations(response.data.payload.locations);
        } else {
          setResultState("danger");
          setResultMessage(t("Locations missing in response"));
        }
      },
      error: function (error) {
        setResultState("danger");
        setResultMessage(t(error.message));
      },
    });
  };

  const updateOffset = (index: number) => {
    setOffset(index);
    getLocations(currentCity.cities_id, query, index, limit);
  };

  const updateQuery = (data: QueryType) => {
    setQuery(data.query);
    getLocations(currentCity.cities_id, data.query, offset, limit);
  };

  useEffect(() => {
    authGetRequest({
      uri: "/cities",
      success: function (response) {
        if (response.data.payload.cities) {
          setCities(response.data.payload.cities);
        } else {
          setResultState("danger");
          setResultMessage(t("Cities missing in response"));
        }
      },
      error: function (error) {
        setResultState("danger");
        setResultMessage(t(error.message));
      },
    });
  }, [t]);

  const renderPagination = () => {
    const max = Math.ceil(locationsCount / limit);
    let pages = 10;
    let start = 0;
    let end = pages;

    if (max <= pages) {
      pages = max;
    }

    if (offset - 5 >= 0) {
      start = offset - 4;
    }

    if (start + pages >= max) {
      start = max - pages;
    }

    if (offset + 5 >= pages) {
      end = offset + 6;
      pages = end;
      if (max <= pages) {
        pages = max;
      }
    }

    return (
      locations.length && (
        <Pagination aria-label="Locations">
          {offset > 1 && (
            <PaginationItem>
              <PaginationLink
                previous
                href="#"
                onClick={() => {
                  updateOffset(offset - 1);
                }}
              />
            </PaginationItem>
          )}
          {start > 1 && (
            <Fragment>
              <PaginationItem>
                <PaginationLink
                  previous
                  href="#"
                  onClick={() => {
                    updateOffset(1);
                  }}
                >
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink previous href="#" onClick={() => {}}>
                  ...
                </PaginationLink>
              </PaginationItem>
            </Fragment>
          )}
          {[...Array(pages)].map((page, i) => {
            if (i >= start && i < end) {
              return (
                <PaginationItem active={i + 1 === offset} key={i}>
                  <PaginationLink
                    onClick={() => {
                      updateOffset(i + 1);
                    }}
                    href="#"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              );
            } else {
              return "";
            }
          })}
          {end < max && (
            <Fragment>
              <PaginationItem>
                <PaginationLink previous href="#" onClick={() => {}}>
                  ...
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  previous
                  href="#"
                  onClick={() => {
                    updateOffset(max);
                  }}
                >
                  {max}
                </PaginationLink>
              </PaginationItem>
            </Fragment>
          )}
          {offset < max && (
            <PaginationItem>
              <PaginationLink
                next
                href="#"
                onClick={() => {
                  updateOffset(offset + 1);
                }}
              />
            </PaginationItem>
          )}
        </Pagination>
      )
    );
  };

  return (
    <Container>
      <LogoRow />
      <Row>
        <Col>
          {resultMessage && (
            <Alert color={resultState}>{t(resultMessage)}</Alert>
          )}
          {cities && (
            //@ts-ignore
            <Accordion flush open={open} toggle={toggle}>
              {cities.map((city: CityType) => (
                <AccordionItem key={`accordion_item_${city.cities_id}`}>
                  <AccordionHeader targetId={city.cities_id}>
                    <Container>
                      <Row>
                        <Col sm="9">{city.name}</Col>
                        <Col sm="3">
                          {city.count} {t("home.locations")}
                        </Col>
                      </Row>
                    </Container>
                  </AccordionHeader>
                  <AccordionBody accordionId={city.cities_id}>
                    {locations.length ? (
                      <Fragment>
                        <Row>
                          <Col sm="12" md="8">
                            {renderPagination()}
                          </Col>
                          <Col sm="12" md="4">
                            <Form onSubmit={handleSubmit(updateQuery)}>
                              <FormGroup>
                                <Row>
                                  <Col sm="8">
                                    <Controller
                                      name="query"
                                      control={control}
                                      render={({ field }) => (
                                        <Input
                                          id="query"
                                          placeholder={t(
                                            "global.query_placeholder"
                                          )}
                                          type="text"
                                          required={true}
                                          invalid={
                                            errors["query"] ? true : false
                                          }
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <Button>{t("home.search")}</Button>
                                  </Col>
                                  <Col sm="1">
                                    <Button
                                      className="btn-danger"
                                      onClick={() => {
                                        reset({
                                          query: "",
                                        });
                                        setQuery(initialQuery);
                                        getLocations(
                                          currentCity.cities_id,
                                          initialQuery,
                                          offset,
                                          limit
                                        );
                                        return false;
                                      }}
                                    >
                                      X
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row>
                        <Table hover responsive>
                          <tbody>
                            {locations.map((location: LocationType) => (
                              <tr key={`location_${location.locations_id}`}>
                                <th>{location.locations_id}</th>
                                <td>{location.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {renderPagination()}
                      </Fragment>
                    ) : (
                      <Spinner>Loading...</Spinner>
                    )}
                  </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Col>
      </Row>
    </Container>
  );
}
