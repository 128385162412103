import { FormFeedback, FormGroup, Label, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IControlledInputProps {
  name: string;
  type: "text" | "password" | "email";
  required: boolean;
  translation: string;
  // TODO: transform any to real types
  control: any;
  errors: any;
}

export default function ControlledInput(
  ControlledInputProps: IControlledInputProps
) {
  const { name, type, required, translation, control, errors } =
    ControlledInputProps;
  const { t } = useTranslation();
  return (
    <FormGroup>
      <Label for={name}>{t(translation)}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            id={name}
            placeholder={t(`${translation}_placeholder`)}
            type={type}
            required={required}
            invalid={errors[name] ? true : false}
            {...field}
          />
        )}
      />
      {errors[name] && (
        <FormFeedback>{t(errors[name].message ?? "")}</FormFeedback>
      )}
    </FormGroup>
  );
}
