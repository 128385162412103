import { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

import LogoRow from "../Shared/LogoRow/LogoRow";
import ControlledInput from "../Shared/ControlledInput/ControlledInput";

type ResetPasswordType = {
  password: string;
  password_confirm: string;
};

export default function ResetPassword() {
  const [resultState, setResultState] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const { token } = useParams();
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const schema = yup
    .object({
      password: yup.string().required(),
      password_confirm: yup
        .string()
        .required()
        .test(
          "passwords-match",
          t("reset_password.errors.password_not_match"),
          function (value) {
            return this.parent.password === value;
          }
        ),
    })
    .required();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      password_confirm: "",
    },
  });
  const onSubmit = (data: ResetPasswordType) => {
    apiUrl &&
      axios
        .post(`${apiUrl}/users/resetpassword`, {
          token: token,
          password: data.password,
          password_confirm: data.password_confirm,
        })
        .then(function (response) {
          setResultState("success");
          setResultMessage(t(response.data.message));
        })
        .catch(function (error) {
          setResultState("danger");
          setResultMessage(t(error.message));
        });
  };
  return (
    <Container>
      <LogoRow />
      <Row>
        <Col
          md={{
            offset: 3,
            size: 6,
          }}
          sm="12"
        >
          {resultMessage && (
            <Alert color={resultState}>{t(resultMessage)}</Alert>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput
              name="password"
              type="password"
              required={true}
              translation="global.password"
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="password_confirm"
              type="password"
              required={true}
              translation="global.password_confirm"
              control={control}
              errors={errors}
            />
            <Button>{t("reset_password.submit")}</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
